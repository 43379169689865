
const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 bg-dark">
            <div className="container">
                <p className="text-light">App desenvolvido por JRMantovani </p>
                <p className="text-light">2021 </p>
              
            </div>
        </footer>
    );
}
export default Footer;
