import "App.css"
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import img1 from "assets/img/img_home.png"
import img2 from "assets/img/img_resistor.png"
import Card from "components/layout/Card";


const Home = () => {
    return (
        <>
     <NavBar />
      <div className="App">
        <h1 className="text-primary px-3">Aplicativo Android</h1>
        <div className='Cards'>

          <Card color={'#338000'} children={'App é uma calculadora de resistor pelo código de cores'} img={img1}
            texto={'telaInicial'} img2={img2}
            texto2={'telaResistor'} titulo={'Calculadora Resistor Pro'}></Card>
         
          
          
     
        </div>
      </div>

      <Footer />
    </>
    );
}

export default Home;