import './Card.css'
import { Link } from "react-router-dom";



const Card =(props) => 
    <div className="Card" style={{
        borderColor: props.color || '#000'
    }}>
        <div className="Content">
            {props.children}
        </div>
        <div className="contanier" >
            
            <img className="imagem" src={props.img} alt={props.texto} width="200" />  
       
        
        </div>
        <div className="button">
             <Link className= "btn btn-primary btn-lg" to="/appcalcresistorpro">
                    Mais detalhes
                </Link>
        </div>
        <div className="Footer" style={{
            backgroundColor: props.color || '#000'
            
        }}>
            {props.titulo}
        </div>
    </div>

export default Card