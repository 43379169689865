import "pages/AppCalcResistorPro/calcresistor.css"
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import img1 from "assets/img/img_home.png"
import img2 from "assets/img/img_resistor.png"
import img3 from "assets/img/telaCores.png"
import img4 from "assets/img/telaresistor5.png"
import img5 from "assets/img/teladivisortensao.png"


const AppCalcResistorPro = () => {
    return (
        <>
            <NavBar />
            <div className="App">
                <h1 className="text-primary px-3">Calculadora Resistor PRO</h1>
                <div>
                    <h3 className="px-3">É uma calculadora de resistores pelo código de cores onde pode se obter o valor da resistência e também calcular o divisor de tensão obtendo os valores de Vin, Vout, R1 e R2 </h3>
                </div>
                <div className='imgs'>

                    <img className="imagens" src={img1} alt="home" width="250" />
                    <img className="imagens" src={img2} alt="resistor band 4" width="250" />
                    <img className="imagens" src={img3} alt="seleciona cor band 1" width="250" />
                    <img className="imagens" src={img4} alt="resistor band 5" width="250" />
                    <img className="imagens" src={img5} alt="divisor de tensao" width="250" />

                </div>

                <div className="button">
                   
                    <a href="https://play.google.com/store/apps/details?id=br.com.jrmantovani.resistorcalculadorapro">
                        <p className=" btn btn-primary btn-lg">Link na play store </p>
                    </a>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AppCalcResistorPro;